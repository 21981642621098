.pcHome {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  position: relative;
}

.pcContent {
  display: flex;
  width: 71.1vw;
  flex-direction: column;
  align-items: center;
}

.pcPageRightBottom {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
}

.pcPageTop {
  display: block;
  position: fixed;
  top: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: -1;
}

.PicPcPageTop {
  display: inline;
  width: 100vw;
}

.sidePicBottomRight {
  display: inline;
  width: 3rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}

.pcHeader {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #f9fafe;
  z-index: 10;
}

.pcFooter {
  display: flex;
  flex-direction: row;
  width: 80%;
  height: 4rem;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  z-index: 10;
}

.footerWrapper {
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 30rem;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  border-radius: 1rem;
  position: relative;
}

.footerText1 {
  display: block;
  width: 100%;
  height: 5rem;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  color: #2d3955;
}

#footerRecoBtn {
  display: flex;
  width: 10rem;
  height: 2rem;
  color: #47536b;
  background-color: #ebf0f9;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 700;
  margin-top: 4rem;
  cursor: pointer;
}

.footerService {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 72%;
}

.footerServiceType {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 28%;
  height: 18rem;
  background-color: white;
  z-index: 5;
  border-radius: 1rem;
  box-shadow: 2px 2px 6px #d6d2d6;
}

.footerText2 {
  display: block;
  width: 80%;
  text-align: left;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  color: #2d3955;
}

.footerText3 {
  display: block;
  width: 80%;
  text-align: right;
  font-size: 0.95rem;
  letter-spacing: 0.1rem;
  color: #7f8999;
}

.closeFooterShow {
  width: 0.9rem;
  height: 0.9rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 0;
}

.logoSFwText {
  display: block;
  height: 2rem;
}

.logoYGwText {
  display: block;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.iconSmall {
  display: block;
  height: 1rem;
  margin-right: 1rem;
}

.iconMiddle {
  display: block;
  height: 1.5rem;
  margin-right: 1rem;
}

.iconMiddleCenter {
  display: block;
  height: 1.5rem;
}

.iconLarge {
  display: block;
  height: 2rem;
  margin-right: 1rem;
}

.iconPlusTitleColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #516a9b;
  background-color: white;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  text-decoration: none;
  align-self: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.iconPlusTitleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.iconPlusTitleRowPn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.navBarPc {
  display: flex;
  width: 36rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
}

.navButtons {
  display: flex;
  width: 22rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
}

.footerButtons {
  display: flex;
  width: 24rem;
  height: 3rem;
  justify-content: space-between;
  align-items: center;
}

.navPcHeader {
  font-size: 0.9rem;
}

.btnPcFooter {
  display: block;
  font-size: 0.95rem;
  color: #7f8999;
}

.btnPcFooter:hover,
:focus:active {
  font-weight: 700;
  color: #516a9b;
}

.textPcFooter {
  font-size: 0.9rem;
  color: #7f8999;
}

.pcHome button {
  display: block;
  width: 5rem;
  height: 2rem;
  background-color: transparent;
  border-width: 0px;
  border-radius: 6px;
}

.pcHome button:hover,
:focus {
  font-weight: 700;
}

.shiftLang {
  display: block;
  width: 5rem;
  height: 2rem;
  color: #516a9b;
  background-color: transparent;
  border-width: 0px;
  border-radius: 6px;
}

.pcMainDivColumnBlue button {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: white;
  border-width: 0px;
  border-radius: 6px;
  color: #2d3955;
  font-weight: 600;
}

.pnMainDivColumnBlue button {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: white;
  border-width: 0px;
  border-radius: 6px;
  color: #47536b;
  font-weight: 600;
  font-size: 0.9rem;
}

.pcMainDivRow button {
  display: block;
  width: 10rem;
  height: 2rem;
  background-color: white;
  border-width: 0px;
  border-radius: 6px;
  color: #2d3955;
  font-weight: 600;
}

.pcMainDivRowCenter button {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: #2d3955;
  border-width: 0px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  margin: 1rem;
}

.pcMainDivRowCenter button:hover {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: #ebf0f9;
  border-width: 1px;
  border-color: #2d3955;
  border-radius: 6px;
  color: #2d3955;
  font-weight: 600;
  margin: 1rem;
}

#buttonLight {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: #ebf0f9;
  border-width: 0px;
  border-radius: 6px;
  color: #2d3955;
  font-weight: 600;
}

#buttonFreeTrial {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: #2d3955;
  border-width: 0px;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 600;
}

#buttonLight:hover {
  display: block;
  width: 8rem;
  height: 2rem;
  background-color: #ebf0f9;
  border-width: 1px;
  border-color: #2d3955;
  border-radius: 6px;
  color: #2d3955;
  font-weight: 600;
}

#userLogon {
  display: flex;
  width: 5rem;
  height: 1.6rem;
  color: white;
  background-color: #516a9b;
  border-style: solid;
  border-width: 1px;
  border-color: #516a9b;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
}

#userRegister {
  display: flex;
  width: 5.2rem;
  height: 1.7rem;
  background-color: #ebf0f9;
  border-width: 0px;
  border-style: solid;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  color: #516a9b;
}

.pcBody {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pcHomeSection1 {
  display: flex;
  width: 80%;
  height: 50rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.product {
  display: block;
  width: 35%;
}

.logoName {
  display: flex;
  width: 7.5rem;
  height: 3rem;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.logoPlusTitle {
  display: flex;
  width: 16rem;
  height: 3rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
  align-self: flex-start;
}

.secTitle {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #47536b;
  width: fit-content;
}

.secTitleLeft {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #47536b;
  width: 100%;
  text-align: left;
}

.secTitleRight {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #47536b;
  width: 100%;
  text-align: right;
}

.secTitlePn {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #47536b;
  text-align: center;
}

.secTitlePnLeft {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #47536b;
  text-align: left;
}

.secTitlePnRight {
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #47536b;
  text-align: right;
}

.secTitleWhite {
  font-size: 1.2rem;
  font-weight: 700;
  color: white;
}

.secTitleWhitePn {
  font-size: 1rem;
  font-weight: 600;
  color: white;
  width: 80%;
  text-align: center;
}

.secTitleS {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #47536b;
}

.secTitlePrice {
  font-size: 1rem;
  font-weight: 700;
  color: #47536b;
}

.secTitleSpn {
  font-size: 1rem;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: #47536b;
  text-align: center;
}

.secSubTitleSpn {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  width: 80%;
  text-align: center;
}

.secTitleSubTitle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.secDescriptionFirstPage {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  padding: 1.6rem;
  z-index: 5;
}

.secDescription {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
}

.secDescriptionCenter {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  align-self: center;
}

.secDescriptionWhite {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: white;
  margin-bottom: 1rem;
}

.secDescriptionWhitePn {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: white;
  margin-bottom: 1rem;
  text-align: center;
  width: 80%;
}

.secDescriptionPn {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  text-align: center;
  width: 100%;
}

.secDescriptionPnFileName {
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #47536b;
  text-align: center;
  margin-top: 0.5rem;
}

.secDescriptionPnLeft {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  text-align: left;
  width: 100%;
}

.secDescriptionPnRight {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
  text-align: right;
  width: 100%;
}

.secDescriptionS {
  font-size: 0.95rem;
  letter-spacing: 0.1rem;
  line-height: 1.3rem;
}

.secDescriptionScenter {
  font-size: 0.95rem;
  letter-spacing: 0.1rem;
  line-height: 1.8rem;
  text-align: center;
  color: #47536b;
}

.picMainA1 {
  width: 90%;
  border-radius: 1rem;
  margin-top: 2rem;
}

.directLink {
  display: block;
  width: auto;
  color: #6984af;
  background-color: transparent;
  font-size: 1rem;
  text-decoration: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.pcDirect {
  display: block;
  width: auto;
  color: #6984af;
  background-color: transparent;
  font-size: 0.95rem;
  text-decoration: none;
  cursor: pointer;
}

.pcDirect:hover,
:focus {
  color: #516a9b;
  font-weight: 700;
}

.pcDirectTicket {
  display: block;
  width: auto;
  color: #6984af;
  background-color: transparent;
  font-size: 0.9rem;
  text-decoration: none;
  cursor: pointer;
  margin: 0.3rem;
}

.pcDirectTicket:hover,
:focus {
  color: #516a9b;
  font-weight: 700;
}

.pcDirectInline {
  display: inline;
  width: auto;
  color: #6984af;
  background-color: transparent;
  font-size: 0.95rem;
  text-decoration: none;
  cursor: pointer;
}

.pcDirectInline:hover,
:focus {
  background-color: transparent;
  color: #516a9b;
  font-weight: 700;
}

#pcDirectResource {
  display: block;
  width: auto;
  color: #6984af;
  background-color: white;
  font-size: 0.95rem;
  text-decoration: none;
  position: absolute;
  top: 3.5rem;
  right: 0;
}

#pcDirectResource:hover,
:focus {
  color: #516a9b;
  font-weight: 700;
}

.pcHomeSection2 {
  display: flex;
  width: 80%;
  height: 24rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pcMainDivRow {
  display: flex;
  width: 100%;
  z-index: 3;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.pcMainDivRowCenter {
  display: flex;
  width: 100%;
  z-index: 3;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pcMainDivColumnBlue {
  display: flex;
  width: 80%;
  height: 15rem;
  z-index: 3;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #516a9b;
  border-radius: 1.5rem;
}

.pnMainDivColumnBlue {
  display: flex;
  width: 90%;
  height: 16rem;
  z-index: 3;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #2d3955;
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.pcDescMain2a {
  display: flex;
  z-index: 3;
  width: 35%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pcDescMain2b {
  display: flex;
  z-index: 3;
  width: 35%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pcDescMain2ab {
  display: block;
  z-index: 3;
  width: 50%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 21%;
  left: 21%;
  z-index: 0;
}

.pcDescMain {
  display: flex;
  z-index: 3;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.pnDescMain {
  display: flex;
  z-index: 3;
  width: 80%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
}

.pnDescMainLeft {
  display: flex;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 1rem;
}

.pcHomeSection31 {
  display: flex;
  width: 80%;
  height: 43rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.picMain31 {
  width: 50%;
  border-radius: 1rem;
}

.pcHomeSection32 {
  display: flex;
  width: 80%;
  height: 53rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.picMain32 {
  width: 40%;
  border-radius: 1rem;
}

.pcDescMain3a {
  display: flex;
  z-index: 3;
  width: 35%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.pcDescMain3b {
  display: block;
  z-index: 3;
  width: 35%;
}

.pcHomeSection4 {
  display: flex;
  width: 80%;
  height: 26rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.secDescription4 {
  font-size: 1rem;
  width: 35%;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  color: #47536b;
}

.pcPicMain4 {
  width: 40%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 1.5rem;
}

.pcPicMain5 {
  width: 64%;
  border-radius: 1.5rem;
}

.pcHomeSection5 {
  display: flex;
  width: 80%;
  height: 64rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 8rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.secDescription5 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 20rem;
  height: 100%;
  font-size: 1rem;
  height: 100%;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
}

.secDescription5text {
  width: 100%;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  margin-top: 2rem;
  color: #47536b;
}

.secDesc5center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#userRegisterS5 {
  display: block;
  width: 75%;
  line-height: 2.5rem;
  color: white;
  background-color: #6984af;
  text-align: center;
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 700;
  border-radius: 0.3rem;
}

#userDownloadS5android {
  display: block;
  width: 75%;
  line-height: 2rem;
  color: #6984af;
  background-color: #ebf0f9;
  text-align: center;
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 700;
  border-radius: 0.3rem;
  margin-bottom: 1rem;
}

#userDownloadS5ios {
  display: block;
  width: 75%;
  line-height: 2rem;
  color: #6984af;
  background-color: #ebf0f9;
  text-align: center;
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 700;
  border-radius: 0.3rem;
  margin-bottom: 2rem;
}

#userRegisterS5Web {
  display: block;
  width: 75%;
  line-height: 2rem;
  color: #6984af;
  background-color: white;
  text-align: center;
  text-decoration: none;
  font-size: 0.95rem;
  font-weight: 700;
  border-radius: 0.3rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
}

.pcProdSection0 {
  display: flex;
  width: 80%;
  height: 4rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 7rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pcProdSection1 {
  display: block;
  width: 80%;
  height: 56rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 7rem;
}

.pcPicProd1a {
  width: 50%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcPicProd1b {
  height: 60%;
  position: absolute;
  bottom: 16%;
  left: 0;
}

.pcDescProd1a {
  display: block;
  width: 35%;
  z-index: 3;
}

.pcDescProd1b {
  display: block;
  width: 55%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}

.pcDescProd1c {
  display: block;
  width: 60%;
  position: absolute;
  bottom: 18%;
  right: 0;
  z-index: 3;
}

.pcProdSection2 {
  display: block;
  width: 80%;
  height: 36rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicProd2a {
  width: 56%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pcPicProd2b {
  width: 30%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcDescProd2a {
  display: block;
  width: 55%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcDescProd2b {
  display: block;
  width: 35%;
  position: absolute;
  top: 56%;
  right: 0;
  z-index: 3;
}

.pcProdSection3 {
  display: block;
  width: 80%;
  height: 36rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicProd3a {
  height: 90%;
  position: absolute;
  top: 0;
  right: 35%;
}

.pcPicProd3b {
  height: 90%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcDescProd3a {
  display: block;
  width: 29%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcProdSection4 {
  display: block;
  width: 80%;
  height: 50rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicProd4a {
  width: 55%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcPicProd4b {
  height: 73%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pcDescProd4a {
  display: block;
  width: 36%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcDescProd4b {
  display: block;
  width: 50%;
  position: absolute;
  top: 72%;
  right: 0;
  z-index: 3;
}

.pcProdSection5 {
  display: block;
  width: 80%;
  height: 50rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicProd5a {
  width: 55%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcPicProd5b {
  width: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pcDescProd5a {
  display: block;
  width: 38%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcDescProd5b {
  display: block;
  width: 50%;
  position: absolute;
  top: 56%;
  right: 0;
  z-index: 3;
}

.pcProdSection6 {
  display: block;
  width: 80%;
  height: 56rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicProd6a {
  height: 73%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcPicProd6b {
  width: 55%;
  position: absolute;
  bottom: 10%;
  left: 0;
}

.pcDescProd6a {
  display: block;
  width: 45%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcDescProd6b {
  display: block;
  width: 35%;
  position: absolute;
  bottom: 5%;
  right: 0;
  z-index: 3;
}

.pcPriceSection0 {
  display: flex;
  width: 80%;
  height: 4rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pcPriceSection1 {
  display: block;
  width: 80%;
  height: 42rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 7rem;
}

.pcDescPrice1a {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcPriceSubSection {
  display: inline-block;
  width: 100%;
  height: 32rem;
  position: absolute;
  top: 8rem;
  left: 0;
}

.pcPicPrice1a {
  height: 30rem;
  width: 80%;
  border-radius: 1rem;
}

.pcDescPrice1b {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  height: 30rem;
  z-index: 5;
  position: absolute;
  top: 0%;
  right: 0;
  border-radius: 1rem;
}

.pcDescPrice1c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 24%;
  height: 23rem;
  background-color: white;
  z-index: 5;
  border-radius: 1rem;
  box-shadow: 2px 2px 6px #d6d2d6;
  position: relative;
  overflow: hidden;
}

.roomTag {
  display: block;
  position: absolute;
  height: 1.3rem;
  width: 9rem;
  font-size: 0.95rem;
  font-weight: 700;
  background-color: #0455bf;
  text-align: center;
  color: white;
  top: 0;
  right: 0;
  transform: translate(3rem, 1rem) rotateZ(45deg);
}

.pcPriceSection2 {
  display: block;
  width: 80%;
  height: 39rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcDescPrice2a {
  display: block;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcPicPrice2a {
  height: 30rem;
  border-radius: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.pcPriceSection3 {
  display: block;
  width: 80%;
  height: 30rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcDescPrice3a {
  display: block;
  width: 45%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcPicPrice3a {
  height: 30rem;
  border-radius: 1rem;
  position: absolute;
  right: 0;
  left: 55%;
}

.pcResourceSection0 {
  display: flex;
  width: 80%;
  height: 4rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pcResourceSection1 {
  display: flex;
  width: 80%;
  height: 36rem;
  border-radius: 1rem;
  margin-top: 2rem;
  margin-bottom: 6rem;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.pcDescResource1a {
  display: block;
  width: 50%;
  height: 30rem;
  border-radius: 1rem;
  margin-top: 10rem;
  margin-bottom: 13rem;
  position: relative;
}

.qrCodeDownloadAndroid {
  display: flex;
  flex-direction: column;
  width: 8rem;
  height: 12rem;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 66%;
  left: 20%;
}

.qrCodeDownloadIos {
  display: flex;
  flex-direction: column;
  width: 8rem;
  height: 12rem;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 66%;
  left: 70%;
}

.pcPicResource1a {
  display: block;
  width: 8rem;
  height: 8rem;
  z-index: 5;
}

.pcPicResource1b {
  width: 39%;
  border-radius: 1rem;
  position: absolute;
  right: 20%;
  top: -5%;
  opacity: 0.39;
}

.pcResourceSection2 {
  display: flex;
  width: 80%;
  height: 10rem;
  border-radius: 1rem;
  margin-top: 5rem;
  margin-bottom: 10rem;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.pcResourceSectionMat {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 6rem;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 1rem;
}

.pcDescResource2b {
  display: flex;
  flex-direction: row;
  height: 6rem;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 1rem;
  margin-right: 2rem;
}

.pcPicResource2a {
  display: block;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.pcResourceSection3 {
  display: block;
  width: 80%;
  height: 52rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcPicResource3a {
  height: 73%;
  position: absolute;
  top: 0;
  right: 0;
}

.pcPicResource3b {
  width: 55%;
  position: absolute;
  bottom: 30%;
  left: 0;
}

.pcDescResource3a {
  display: block;
  width: 45%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.pcDescResource3b {
  display: block;
  width: 55%;
  position: absolute;
  bottom: 5%;
  right: 0;
  z-index: 3;
}

.pcResourceSectionQ {
  display: block;
  width: 80%;
  height: 42rem;
  margin-top: 5rem;
  margin-bottom: 7rem;
}

.pcFaqSearch {
  display: flex;
  width: 80%;
  height: 3rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.iconSectionQsearch {
  width: 1.2rem;
  padding: 0.3rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.iconSectionQundo {
  width: 0.8rem;
  padding: 0.1rem;
  margin-left: 1.5rem;
  margin-right: 0.5rem;
  opacity: 0.9;
  cursor: pointer;
}

.pcFaqSearchInput {
  display: inline-block;
  width: 60%;
  height: 2.5rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding-left: 0.5rem;
}

input:placeholder-shown {
  color: #d6d2d6;
  font-size: 0.9rem;
  padding-left: 0.5rem;
}

input:focus {
  color: #47536b;
  font-size: 0.95rem;
  padding-left: 0.5rem;
  background-color: #ebf0f9;
  border-color: #516a9b;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  outline: none;
}

.pcFaqItems {
  display: block;
  width: 100%;
  height: 39rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  scroll-behavior: auto;
  overflow: scroll;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pcFaqItems::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.faqQuestion {
  font-weight: 700;
  color: #47536b;
}

.faqAnswer {
  margin-bottom: 1rem;
  color: #47536b;
}

/*below settings are only applicable for phone-based screens*/

.phoneHome {
  display: flex;
  width: 100vw;
  flex-direction: column;
  align-items: center;
}

.pnHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 6rem;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
}

.pnHeaderLine1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  background-color: #ebf0f9;
  justify-content: space-between;
  align-items: center;
}

.pnHeaderLine2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 3rem;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
}

.btnHeaderLine2Pn {
  display: block;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.9rem;
  margin-left: 1rem;
  color: #7f8999;
  background-color: transparent;
  border-width: 0;
}

.shiftLangPn {
  display: block;
  width: 3rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.8rem;
  margin-left: 1rem;
  color: #7f8999;
  background-color: transparent;
  border-width: 0;
}

.btnHeaderLine2Pn:hover,
:focus:active {
  font-weight: 700;
  color: #516a9b;
}

.pnBody {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pnBody::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.pnWrapperRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pnWrapperRowRight {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.pnWrapperRowInside {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pnWrapperColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pnWrapperColumnLeft {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.pnImageInSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
}

.pnImageInSectionS {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
}

.footerWrapperPn {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-height: 34rem;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
  border-radius: 1rem;
}

.pnFooter {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  z-index: 10;
}

.footerButtonsPn {
  display: flex;
  width: 100%;
  height: 3rem;
  justify-content: space-around;
  align-items: center;
  background-color: #ebf0f9;
}

.btnPnFooter {
  display: block;
  height: 1rem;
  font-size: 0.9rem;
  color: #47536b;
  background-color: transparent;
  border-style: none;
  border-width: 0;
}

.btnPnFooter:hover,
:focus:active {
  font-weight: 700;
  color: #0455bf;
}

.textPnFooter {
  display: flex;
  flex-direction: row;
  font-size: 0.8rem;
  color: #7f8999;
  height: 3rem;
  justify-content: center;
  align-items: center;
}

.footerText1Pn {
  display: block;
  width: 100%;
  height: 3rem;
  text-align: left;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  color: #47536b;
}

#footerRecoBtnPn {
  display: flex;
  width: 10rem;
  height: 2rem;
  color: #47536b;
  background-color: #ebf0f9;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 700;
  margin-top: 4rem;
  outline: none;
  border-width: 0;
  border-radius: 0.5rem;
}

.closeFooterShowPn {
  display: flex;
  width: 0.7rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  opacity: 0.7;
  align-self: flex-end;
}

.pnPicQrcode {
  display: block;
  width: 8rem;
  height: 8rem;
  margin-top: 2rem;
  z-index: 5;
}

.footerServicePn {
  display: block;
  width: 90%;
  height: 20rem;
  text-align: center;
  margin: 0 auto;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.footerServicePn::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.footerServiceTypePn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 12rem;
  text-align: center;
  margin: 0 auto;
  background-color: transparent;
  z-index: 5;
  border-radius: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pnDirectInline {
  display: inline;
  width: auto;
  color: #6984af;
  background-color: transparent;
  font-size: 0.9rem;
  text-decoration: none;
}

.footerText2Pn {
  display: block;
  width: 90%;
  text-align: left;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  color: #47536b;
}

.footerText3Pn {
  display: block;
  width: 90%;
  text-align: center;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  color: #7f8999;
}

.secDescriptionPn {
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
}

.pnPriceSubSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  align-self: center;
}

.pnDescPrice1c {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  margin-bottom: 2rem;
  border-bottom: #6984af;
  border-top: white;
  border-left: white;
  border-right: white;
  border-style: dotted;
  border-width: 1px;
  border-radius: 1rem;
}

.pnDirect {
  display: block;
  width: auto;
  color: #6984af;
  background-color: white;
  font-size: 0.9rem;
  text-decoration: underline;
}

.pnDirect:hover,
:focus {
  color: #516a9b;
  font-weight: 700;
}

.pnDirectInline {
  display: inline;
  width: auto;
  color: #516a9b;
  background-color: white;
  font-size: 0.9rem;
  text-decoration: none;
}

.pnDirectInline:hover,
:focus {
  color: #516a9b;
  font-weight: 700;
}

.pnResourceSectionQ {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 24rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  align-self: center;
  align-items: center;
  justify-content: flex-start;
}

.pnDescResource2b {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 1rem;
}

.pnResourceSectionMat {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 2rem;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  margin-top: 2rem;
}

.pnFaqSearch {
  display: flex;
  width: 100%;
  height: 3rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pnFaqSearchInput {
  display: inline-block;
  width: 60%;
  height: 2.5rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  padding-left: 0.5rem;
}

.pnFaqItems {
  display: block;
  width: 100%;
  height: 20rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  scroll-behavior: auto;
  overflow: scroll;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  line-height: 1.5rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pnFaqItems::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.logoPlusTitlePn {
  display: flex;
  width: 16rem;
  height: 3rem;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 2rem;
  align-self: flex-start;
}

.pcInquiryBackground {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(137, 160, 205, 0.5);
  z-index: 30;
  position: absolute;
  top: 0%;
  left: 0%;
}

.pcInquiryForm {
  display: flex;
  width: 750px;
  height: 460px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9fafd;
  border-radius: 2rem;
  padding: 2rem;
}

.pcInquiryInput {
  display: inline-block;
  width: 30rem;
  height: 2.5rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  margin-bottom: 1.5rem;
}

.pcInquiryInputText {
  display: inline-block;
  width: 29.5rem;
  height: 7.5rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  font-size: 0.9rem;
  font-family: Arial, Microsoft YaHei, SimHei, -apple-system, BlinkMacSystemFont, PingFang SC, sans-serif;
  padding: 0.5rem;
}

.pcInquiryInputText:focus {
  color: #47536b;
  font-size: 1rem;
  padding: 0.5rem;
  background-color: #ebf0f9;
  border-color: #516a9b;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.5rem;
  outline: none;
}

.pcInquiryInputBox {
  display: flex;
  flex-direction: row;
  width: 37rem;
  height: 2.5rem;
  justify-content: space-between;
  align-items: first baseline;
  margin-bottom: 1.5rem;
}

.pcInquiryInputBoxHeaderText {
  display: flex;
  flex-direction: row;
  width: 37rem;
  height: 2.5rem;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 2rem;
}

.inquiryTitleS {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333333;
  margin-right: 2rem;
}

.pcInquiryInputBoxText {
  display: flex;
  flex-direction: row;
  width: 37rem;
  height: 7.5rem;
  justify-content: space-between;
  align-items: first baseline;
  margin-bottom: 4.5rem;
}

.pcInquiryButtonBox {
  display: flex;
  flex-direction: row;
  width: 37rem;
  height: 2.5rem;
  justify-content: center;
  align-items: baseline;
}

.pcInquiryButtonBox button {
  display: flex;
  width: 9rem;
  height: 2rem;
  color: #516a9b;
  background-color: #ebf0f9;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 5rem;
}

.pcInquiryHeaderText {
  display: block;
  width: 100%;
  height: 5rem;
  text-align: left;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #bf0404;
}

/* Phone */

.pnInquiryBackground {
  display: flex;
  width: 100vw;
  height: 200vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  z-index: 30;
  position: absolute;
  top: 0%;
  left: 0%;
}

.pnInquiryForm {
  display: flex;
  width: 100vw;
  height: 88vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9fafd;
}

.pnInquiryInput {
  display: inline-block;
  width: 18rem;
  height: 2.5rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.3rem;
  font-size: 1rem;
  padding-left: 0.5rem;
  margin-bottom: 1rem;
}

.pnInquiryInputText {
  display: inline-block;
  width: 17.5rem;
  height: 8rem;
  border-color: #ebf0f9;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.3rem;
  font-size: 0.9rem;
  font-family: Arial, Microsoft YaHei, SimHei, -apple-system, BlinkMacSystemFont, PingFang SC, sans-serif;
  padding: 0.5rem;
}

.pnInquiryInputText:focus {
  color: #47536b;
  font-size: 1rem;
  padding: 0.5rem;
  background-color: #ebf0f9;
  border-color: #516a9b;
  border-style: solid;
  border-width: 1px;
  border-radius: 0.3rem;
  outline: none;
}

.pnInquiryInputBox {
  display: flex;
  flex-direction: column;
  width: 19rem;
  height: 5.5rem;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.pnInquiryInputBoxHeaderText {
  display: flex;
  flex-direction: column;
  width: 19rem;
  height: 3rem;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3rem;
}

.pnInquiryTitleS {
  font-size: 0.9rem;
  font-weight: 500;
  color: #333333;
  margin-right: 2rem;
}

.pnInquiryInputBoxText {
  display: flex;
  flex-direction: column;
  width: 19rem;
  height: 7rem;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.pnInquiryButtonBox {
  display: flex;
  flex-direction: row;
  width: 19rem;
  height: 3rem;
  justify-content: center;
  align-items: baseline;
}

.pnInquiryButtonBox button {
  display: flex;
  width: 7rem;
  height: 2rem;
  color: #516a9b;
  background-color: #ebf0f9;
  border-radius: 6px;
  border-width: 0px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1rem;
  margin-right: 1rem;
}

.pnInquiryHeaderText {
  display: block;
  width: 100%;
  height: 3rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  color: #bf0404;
}
