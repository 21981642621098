* {
  margin: 0;
  padding: 0;
}

html {
  font-size: 14px;
}

button:focus {
  outline: none;
}

@media screen and (min-width: 2560px) {
  * {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 18px;
  }

  button:focus {
    outline: none;
  }
}

@media screen and (min-width: 3840px) {
  * {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 28px;
  }

  button:focus {
    outline: none;
  }
}
